.about_wrapper {
  h3 {
    color: $secondary-color;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 24px;
  }
  p {
    color: #555555;
    line-height: 28px;
    text-align: justify;
    margin-bottom: 15px;
  }
  .innerpage_about_img {
    margin-bottom: 50px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    &::before {
      position: absolute;
      content: "";
      left: 50%;
      top: 50%;
      height: 97%;
      width: 97%;
      transform: translate(-50%, -50%);
      border: 4px solid #ffffff;
      z-index: 1;
    }
    &::after {
      position: absolute;
      content: "";
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      background: rgba($color: #000000, $alpha: 0.3);
    }
  }
}
