.product-info-wrapper {
  h5 {
    color: #555555;
    font-size: 22px;
    margin-bottom: 15px;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  ul {
    list-style: disc;
    color: #555555;
    padding-left: 25px;
    li {
      padding-bottom: 10px;
    }
  }
  .view_all {
    margin-top: 20px;
  }
}
.product_slider_wrapper {
  .slick-slider {
    padding-left: 80px;
  }
  .slick-slide img {
    height: 420px;
    object-fit: cover;
    border-radius: 4px;
    overflow: hidden;
  }
}
.product_slider {
  .slick-arrow {
    background-color: $secondary-color;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    opacity: 0.5;
    overflow: hidden;
    @include transitions(300ms, ease-in);
    &:hover {
      opacity: 1;
    }
  }
  .slick-prev {
    left: -40px;
  }
  .slick-next {
    right: -40px;
  }
}
