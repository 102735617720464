.testinomial_item {
  margin: 0 10px;
  h3 {
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 24px;
    color: $base-color;
    font-style: italic;
  }
  p {
    color: #555555;
    font-style: italic;
    line-height: 28px;
    // font-weight: 600;
    margin-bottom: 30px;
  }
  figure {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    margin-bottom: 30px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
}
.testimonials_wrapper {
  .slick-dots li.slick-active button:before {
    color: #e50c0c !important;
  }
}
