.form-group {
  label {
    font-size: 14px;
    color: #555555;
  }
  .form-control {
    box-shadow: none;
    border-radius: 0px;
    height: 55px;
    font-size: 14px;
  }
  textarea {
    &.form-control {
      height: 200px;
    }
  }
}
