.footer {
  padding: 60px 0;
  position: relative;
  z-index: 1;
  background-image: repeating-linear-gradient(
      333deg,
      hsla(119, 47%, 93%, 0.05) 0px,
      hsla(119, 47%, 93%, 0.05) 1px,
      transparent 1px,
      transparent 11px,
      hsla(119, 47%, 93%, 0.05) 11px,
      hsla(119, 47%, 93%, 0.05) 12px,
      transparent 12px,
      transparent 32px
    ),
    repeating-linear-gradient(
      45deg,
      hsla(119, 47%, 93%, 0.05) 0px,
      hsla(119, 47%, 93%, 0.05) 1px,
      transparent 1px,
      transparent 11px,
      hsla(119, 47%, 93%, 0.05) 11px,
      hsla(119, 47%, 93%, 0.05) 12px,
      transparent 12px,
      transparent 32px
    ),
    repeating-linear-gradient(
      135deg,
      hsla(119, 47%, 93%, 0.05) 0px,
      hsla(119, 47%, 93%, 0.05) 1px,
      transparent 1px,
      transparent 11px,
      hsla(119, 47%, 93%, 0.05) 11px,
      hsla(119, 47%, 93%, 0.05) 12px,
      transparent 12px,
      transparent 32px
    ),
    repeating-linear-gradient(
      0deg,
      hsla(119, 47%, 93%, 0.05) 0px,
      hsla(119, 47%, 93%, 0.05) 1px,
      transparent 1px,
      transparent 11px,
      hsla(119, 47%, 93%, 0.05) 11px,
      hsla(119, 47%, 93%, 0.05) 12px,
      transparent 12px,
      transparent 32px
    ),
    repeating-linear-gradient(
      90deg,
      hsla(119, 47%, 93%, 0.05) 0px,
      hsla(119, 47%, 93%, 0.05) 1px,
      transparent 1px,
      transparent 11px,
      hsla(119, 47%, 93%, 0.05) 11px,
      hsla(119, 47%, 93%, 0.05) 12px,
      transparent 12px,
      transparent 32px
    ),
    linear-gradient(90deg, rgb(130, 26, 221), rgb(63, 178, 202));
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: -1;
  }
  .footer_links {
    li {
      padding-bottom: 10px;
      font-weight: 500;
      position: relative;
      font-weight: 500;
      padding-left: 20px;
      font-size: 14px;
      &::after {
        position: absolute;
        content: "\f101";
        font-family: FontAwesome;
        left: 0px;
        top: 0px;
        color: #d2d2d2;
      }
      a {
        color: #d2d2d2;
        text-transform: uppercase;
        font-size: 15px;
      }
    }
  }
  h3 {
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 30px;
    font-size: 23px;
    font-weight: 600;
  }
  p {
    color: #d2d2d2;
    text-align: justify;
    line-height: 28px;
    font-weight: 500;
    font-size: 14px;
  }
  .footer_address {
    li {
      color: #d2d2d2;
      padding-bottom: 10px;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .social_media {
    margin-top: 15px;
    li {
      display: inline-block;
      margin-left: 10px;

      border-radius: 4px;
      overflow: hidden;
      // &.facebook {
      //   a {
      //     background: #3b5998;
      //   }
      // }
      // &.twitter {
      //   a {
      //     background: #00aced;
      //   }
      // }
      // &.youtube {
      //   a {
      //     background: #bb0000;
      //   }
      // }
      // &.instagram {
      //   a {
      //     background: #bc2a8d;
      //   }
      // }
      &:first-child {
        margin-left: 0px;
      }
      a {
        color: #d2d2d2;
        display: block;
        padding: 9px 10px;
        font-size: 20px;
        -webkit-transition: all 300ms ease-in;
        -moz-transition: all 300ms ease-in;
        -o-transition: all 300ms ease-in;
        transition: all 300ms ease-in;
        &:hover {
          color: $base-color;
        }
      }
    }
  }
}
.company_footer {
  background: #000000;
  text-align: right;
  padding: 5px 0;
  text-transform: uppercase;
  p {
    color: #ffffff;
    font-size: 13px;
    svg {
      display: inline-block;
      position: relative;
      top: -2px;
      margin-left: 5px;
      margin-right: 5px;
      color: $secondary-color;
      font-size: 18px;
    }
  }
}
