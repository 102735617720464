.innerpage_banner {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  h1 {
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 600;
  }
}
