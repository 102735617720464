.scroll_to_top {
  width: 50px;
  height: 50px;
  background-color: $secondary-color;
  text-align: center;
  line-height: 50px;
  color: #ffffff;
  border-radius: 50%;
  overflow: hidden;
  position: fixed;
  z-index: 999;
  bottom: 50px;
  right: 20px;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
