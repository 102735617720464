.admin_sidebar {
  height: 100vh;
  background-color: #212121;
  width: 240px;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow-y: scroll;
  position: absolute;
  &::-webkit-scrollbar {
    display: none;
  }
}
