body {
  font-family: $base-font;
  width: 100%;
  position: relative;
  margin: 0px;
  padding: 0px;
  color: #000000;
  font-weight: 400;
  font-size: $base-font-size;
}
a {
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}
img {
  width: 100%;
}
figure {
  margin-bottom: 0px;
}
.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.overlay {
  position: relative;
  z-index: 1;
  &::after {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba($color: #000000, $alpha: 0.6);
  }
}
ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.bg-gray {
  background-color: $bg-gray;
}
.margin-0 {
  margin: $margin-0;
}
.padding-0 {
  padding: $padding-0;
}
.padd-top-0 {
  padding-top: 0px;
}
.padd-btm-0 {
  padding-bottom: 0;
}
.margin-top-0 {
  margin-top: 0px;
}
.margin-btm-0 {
  margin-bottom: 0;
}

.section {
  padding: $section-padding;
}
h1 {
  font-size: $h1-font-size;
  margin: 0px;
  padding: 0px;
}
h3 {
  font-size: $h3-font-size;
  margin: 0px;
  padding: 0px;
}
p {
  font-size: $p-font-size;
  margin: 0px;
  padding: 0px;
}
span {
  //   font-size: 15px;
  color: $span-color;
}
.section-title {
  h3 {
    font-weight: 600;
    color: $secondary-color;
  }
}
.box-shadow {
  box-shadow: $box-shadow;
}
.pos-relative {
  position: $pos-relative;
}
.pos-absolute {
  position: $pos-absolute;
}
.bg-gray-light {
  background-color: $bg-gray-light;
}
