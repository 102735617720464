.section-title {
  position: relative;
  z-index: 1;
  span {
    color: #eee;
    font-size: 50px;
    line-height: 0;
    position: absolute;
    top: 15px;
    left: 0px;
    width: 100%;
    opacity: 0.8;
    font-weight: 700;
    z-index: -1;
    text-transform: uppercase;
  }
}
.bg-gray {
  .section-title {
    span {
      color: #ece8e8;
    }
  }
}
