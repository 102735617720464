// $base-font: "Source Sans Pro", sans-serif;
$base-font: "Work Sans", sans-serif;
$secondary-font: "Roboto", sans-serif;
$base-font-size: 16px;
$bg-gray-light: #f1f1f1;
$base-color: #00a3e8;
$secondary-color: #e50c0c;

$bg-gray: #f7f7f7;

$h1-font-size: 40px;
$h3-font-size: 28px;
$p-font-size: 16px;
$span-color: #34495e;
$section-padding: 60px 0;
$section-margin: 60px 0;
$padding-0: 0;
$margin-0: 0;
$border-color: #ddd;
$box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
$pos-relative: relative;
$pos-absolute: absolute;
$text-uppercase: uppercase;
