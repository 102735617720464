.map_wrapper {
  .leaflet-container {
    height: 500px;
  }
}
.contact_info {
  ul {
    li {
      padding: 15px;
      background: #ffffff;
      position: relative;
      padding-left: 60px;
      svg {
        color: #000;
        position: absolute;
        left: 0px;
        font-size: 34px;
        top: 45px;
        opacity: 0.3;
      }
      h3 {
        font-size: 22px;
        text-transform: uppercase;
        color: #212121;
        margin-bottom: 10px;
        margin-top: 15px;
      }
      p {
        color: #555555;
      }
    }
  }
}
.contact_form {
  padding: 25px;
  background: #ffffff;
}
.map_wrapper {
  &::after {
    clear: both;
    display: block;
    content: "";
  }
  .map_section {
    float: left;
    width: 60%;
    box-sizing: border-box;
  }
  .contact_information_detail_section {
    float: right;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-top: 60px;
    background-color: #ffffff;
  }
}
